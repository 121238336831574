import {
  Avatar,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
  Stack,
  Button,
  InputBase,
} from "@mui/material";
//__api__
import { setNotificationReadRequest } from "src/__api__/notification";
import { confirmContactRequest } from "src/__api__/chats";
import { fetchUserRequest } from "src/__api__/officeInfo";
//recoil
import { useSetRecoilState } from "recoil";
import alertAtom from "src/recoil/atoms/alertAtom";
//react-router-dom
import { useNavigate } from "react-router-dom";
//react
import { useCallback, useEffect, useState } from "react";
import { PATH_DASHBOARD } from "src/routes/paths";

function FirstTimeChat(props) {
  const triggerAlert = useSetRecoilState(alertAtom);
  const navigate = useNavigate();

  const { name, id, listId, contactId, avatar } = props;
  console.log("contactid", contactId);

  const confirmContact = useCallback(async (id, body) => {
    confirmContactRequest(id, body)
      .then((response) => {
        setNotificationRead(id);
        navigate(PATH_DASHBOARD.chats.listChats);
        triggerAlert({
          triggered: true,
          type: "success",
          message: "تم الاضافة بنجاح",
        });
      })
      .catch((error) => {
        console.log("error confirm Contact read", error);
        triggerAlert({ triggered: true, type: "error", message: "حدث خطا ما" });
      });
  }, []);

  const setNotificationRead = useCallback(async (id) => {
    setNotificationReadRequest(id)
      .then((response) => {
        console.log("notification", response);
      })
      .catch((error) => {
        console.log("error set Notification read", error);
        // triggerAlert({ triggered: true, type: "error", message: "حدث خطا ما" });
      });
  }, []);

  const handleAvatar = useCallback(() => {
    let context;
    if (avatar == "null") {
      context = <Avatar sx={{ width: 200, height: 200 }} />;
    } else {
      context = (
        <Avatar sx={{ width: 200, height: 200 }}>
          <Box
            component="img"
            src={`https://borsacloud.com/storage/app/public/${avatar}`}
          />
        </Avatar>
      );
    }
    return context;
  }, [avatar]);

  return (
    <Box>
      <Stack mt={15}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {handleAvatar()}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Typography variant="h3">{name}</Typography>
        </Box>
        <Divider sx={{ mt: 11 }} />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Stack>
            <Typography variant="subtitle1" mr={1.5}>
              قام {name} بأضافتك كجهة اتصال
            </Typography>
            <Typography>هل تريد قبول المراسلة والاستمرار في الحديث</Typography>
          </Stack>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Stack direction="row">
            <Button
              variant="outlined"
              sx={{ border: 2, width: 313, height: 48 }}
              onClick={() =>
                confirmContact(listId, {
                  contact_id: contactId,
                  status: "reject",
                })
              }
            >
              رفض
            </Button>
            <Button
              variant="outlined"
              sx={{ border: 2, width: 313, height: 48, mr: 1 }}
              onClick={() =>
                confirmContact(listId, {
                  contact_id: contactId,
                  status: "confirm",
                })
              }
            >
              موافقة
            </Button>
          </Stack>
        </Box>
        {/* <ChatBar /> */}
      </Stack>
    </Box>
  );
}
export default FirstTimeChat;
