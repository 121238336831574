import axios from "axios";

// Define the main URL
export const mainUrl = "https://borsacloud.com";

// Create an axios instance for regular API calls
const axiosInstance = axios.create({
  baseURL: mainUrl,
  timeout: 100000000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  withCredentials: true, // Ensure cookies are sent with requests for Sanctum
});

// Function to update the Authorization header based on the current access token
function updateAuthorizationHeader() {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
}

// Call this function initially to set up the Authorization header
updateAuthorizationHeader();

// Handling refresh token logic if necessary (assuming you have an endpoint for this)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // mark it so that we don't get into an infinite loop
      window.location.href = "/auth/login";
    }
    // Handle logout or redirect to login if refresh is also unauthorized
    if (error.response.status === 401 && originalRequest._retry) {
      // Possible logout operation or redirect to login
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
