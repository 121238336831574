//MUI
import {
  Box,
  Avatar,
  Stack,
  InputBase,
  Tooltip,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { LoadingButton } from "@mui/lab";
//iconify
import Iconify from "../../components/Iconify";
// Formik
import { useFormik } from "formik";
// Yup
import * as Yup from "yup";
//__api__
import { sendMessageRequest } from "src/__api__/chats";
//recoil
import { useSetRecoilState } from "recoil";
import alertAtom from "src/recoil/atoms/alertAtom";
//react
import { useCallback, useEffect, useState } from "react";
import { UploadAvatar } from "../upload";

function ChatBar({ handelerSend, contact_id, handelerUpdate }) {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioData, setAudioData] = useState(null);

  const formik = useFormik({
    initialValues: {
      receiver_id: contact_id,
      message: "", // Ensure this is the correct initial value you want after reset
      message_type: "text",
    },
    validationSchema: Yup.object().shape({
      receiver_id: Yup.string().required(""),
      message_type: Yup.string().required(""),
    }),
    onSubmit: async (values, { resetForm }) => {
      await sendMessageRequest(values)
        .then((response) => {
          // After successful message send, reset the form to initial values
          resetForm();
          console.log("Message sent successfully");
          // If you have additional state updates related to sending the message, do them here.
        })
        .catch((error) => {
          triggerAlert({
            triggered: true,
            type: "error",
            message: "حدث خطا ما",
          });
          console.log("Error send message", error.response);
        });
    },
  });

  useEffect(() => {
    // Reset form with new initial values when contact_id changes
    formik.resetForm({
      values: {
        receiver_id: contact_id,
        message: "",
        message_type: "text",
      },
    });
  }, [contact_id, formik.resetForm]);

  const {
    values,
    setFieldValue,
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    dirty,
  } = formik;

  const triggerAlert = useSetRecoilState(alertAtom);
  console.log("chatbar", contact_id);

  const [exitImage, setExitImage] = useState(false);
  console.log(exitImage);

  var audioBlob;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      // Collect audio data as it comes in
      const audioChunks = [];
      recorder.ondataavailable = (event) => {
        audioChunks.push(event.data);
      };

      // When recording stops, compile the chunks into a single audio Blob
      recorder.onstop = () => {
        audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        setFieldValue("message", audioBlob);
        setFieldValue("message_type", "voice");
      };

      // Start recording
      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop(); // This triggers the 'onstop' event
      setIsRecording(false);
      console.log("stopRecc");
    }
  };

  const [filePreview, setFilePreview] = useState(null);

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setFieldValue("message", file);
      setFieldValue("message_type", "img");
      setFilePreview({
        ...file,
        preview: URL.createObjectURL(file),
      });
    }
  }, []);

  const handelerExite = () => {
    setFieldValue("message_type", "text");
    setFieldValue("message", "");
    setExitImage(false);
  };

  const submitButtonHandler = useCallback(() => {
    if (!dirty) {
      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    } else {
      handleSubmit();
    }
  }, [isRecording, dirty]);
  //editttttt
  const submitIconButtonHandler = useCallback(() => {
    var submitIcon;
    if (!dirty) {
      if (isRecording) {
        submitIcon = "tabler:send";
      } else {
        submitIcon = "bi:mic";
      }
    } else {
      submitIcon = "tabler:send";
    }
    return submitIcon;
  }, [isRecording, dirty]);

  const voiceImageShowHandler = useCallback(() => {
    let context;
    if (values.message == "[object File]") {
      context = (
        <TextField
          placeholder="اكتب الرسالة"
          autoComplete="off" // This line disables the autocomplete feature
          sx={{
            flexGrow: 1,
            marginRight: 1,
            mt: -0.5,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // removes the border
              },
              "&:hover fieldset": {
                border: "none", // removes the border on hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // removes the border when focused
              },
            },
          }}
          value={"Image"}
          disabled
          InputProps={{
            style: {
              border: "none", // This removes the border
              "&:hover": {
                border: "none", // Removes border on hover
              },
              "&:focused": {
                border: "none", // Removes border when focused
              },
            },
          }}
        />
      );
    } else if (values.message == "[object Blob]") {
      context = (
        <TextField
          placeholder="اكتب الرسالة"
          autoComplete="off" // This line disables the autocomplete feature
          sx={{
            flexGrow: 1,
            marginRight: 1,
            mt: -0.5,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // removes the border
              },
              "&:hover fieldset": {
                border: "none", // removes the border on hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // removes the border when focused
              },
            },
          }}
          value={"Voice"}
          disabled
          InputProps={{
            style: {
              border: "none", // This removes the border
              "&:hover": {
                border: "none", // Removes border on hover
              },
              "&:focused": {
                border: "none", // Removes border when focused
              },
            },
          }}
        />
      );
    } else {
      context = isRecording ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "error.main",
              mx: 2,
              animation: "fadeInOut 1.5s infinite",
              "@keyframes fadeInOut": {
                "0%, 100%": { opacity: 0 },
                "50%": { opacity: 1 },
              },
            }}
          >
            Recording
          </Typography>
          <IconButton
            onClick={() => {
              setFieldValue("message", "");
              setIsRecording(false);
            }}
            sx={{ cursor: "pointer" }}
          >
            <Iconify
              icon="material-symbols:stop"
              sx={{ color: "error.main" }}
            />
          </IconButton>
        </Box>
      ) : (
        <TextField
          placeholder="اكتب الرسالة"
          autoComplete="off" // This line disables the autocomplete feature
          sx={{
            flexGrow: 1,
            marginRight: 1,
            mt: -0.5,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // removes the border
              },
              "&:hover fieldset": {
                border: "none", // removes the border on hover
              },
              "&.Mui-focused fieldset": {
                border: "none", // removes the border when focused
              },
            },
          }}
          value={values.message}
          onChange={(event) => setFieldValue("message", event.target.value)}
          {...getFieldProps("message")}
          error={Boolean(touched.message) && errors.message}
          helperText={Boolean(touched.message) && errors.message}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault(); // Prevent the default action to avoid submitting the form
              handleSubmit(); // This will trigger formik's onSubmit
            }
          }}
          InputProps={{
            style: {
              border: "none", // This removes the border
              "&:hover": {
                border: "none", // Removes border on hover
              },
              "&:focused": {
                border: "none", // Removes border when focused
              },
            },
          }}
        />
      );
    }
    return context;
  }, [values.message, isRecording]);

  return (
    <Box>
      <Box sx={{ display: exitImage ? "block" : "none" }}>
        <Iconify
          onClick={handelerExite}
          icon="gridicons:cross"
          sx={{ cursor: "pointer" }}
        />
        <UploadAvatar
          accept="image/*"
          file={filePreview}
          onDrop={handleDrop}
          error={Boolean(touched.message && errors.message)}
        />
      </Box>
      <Stack direction="row">
        <Box
          sx={{
            bgcolor: "#F3F2FF",
            height: 44,
            width: "100%",
            borderRadius: "30px",
            mt: 3,
          }}
        >
          <Stack direction="row">
            <Iconify
              sx={{ height: 24, width: 24, mt: 1.2, mr: 1 }}
              icon="mingcute:emoji-line"
            />
            {voiceImageShowHandler()}
            <InsertLinkIcon
              onClick={() => setExitImage(true)}
              sx={{ height: 24, width: 24, mt: 1.2, ml: 2, cursor: "pointer" }}
            />
          </Stack>
        </Box>
        <Box sx={{ cursor: "pointer" }}>
          <Avatar sx={{ bgcolor: "primary.main", mt: 3, ml: 3, mr: 1 }}>
            <Tooltip title={dirty ? "send" : "voice"}>
              <LoadingButton
                variant="contained"
                // disabled={!dirty}
                onClick={submitButtonHandler}
                loading={isSubmitting}
              >
                <Iconify
                  sx={{ height: 24, width: 24 }}
                  icon={submitIconButtonHandler()}
                  color="white"
                />
              </LoadingButton>
            </Tooltip>
          </Avatar>
        </Box>
      </Stack>
    </Box>
  );
}

export default ChatBar;
