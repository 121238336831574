//MUI-
import { Box, Button, Container, Typography } from "@mui/material";

//--------------------------------------------------------------------------------

export default function OfflineComponent() {
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        component="img"
        src="/no-internet.png"
        sx={{
          width: "15vw",
          height: "15vw",
          marginBottom: 3,
        }}
      />
      <Typography variant="h3" sx={{ marginBottom: 3 }}>
        تعذر الاتصال بالانترنت
      </Typography>
      <Button onClick={reloadPage} variant="contained" sx={{ marginBottom: 3 }}>
        حاول مرة اخري
      </Button>
    </Container>
  );
}
