import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
// paths
import { PATH_DASHBOARD, PATH_AUTH } from "./paths";
// components
import LoadingScreen from "../components/LoadingScreen";
import LayoutGenerator from "src/layouts";
import DashboardLayout from "src/layouts/dashboard";
import HorizontalLayout from "src/layouts/horizontal";
import VerticalLayout from "src/layouts/vertical";
import Chatpage from "src/pages/chats/Chatpage";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <LayoutGenerator />,
    },
    //auth
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: <LoginPage />,
        },
        {
          path: PATH_AUTH.forgetPassword,
          element: <ForgetPasswordPage />,
        },
        {
          path: PATH_AUTH.resetPassword,
          element: <ResetPasswordPage />,
        },
      ],
    },
    // Overview
    {
      path: PATH_DASHBOARD.overview.root,
      element: <HorizontalLayout />,
      children: [{ path: PATH_DASHBOARD.overview.home, element: <HomePage /> }],
    },
    // Chats
    {
      path: PATH_DASHBOARD.chats.root,

      children: [
        {
          path: PATH_DASHBOARD.chats.listChats,
          element: (
            <VerticalLayout>
              <ListChatsPage />
            </VerticalLayout>
          ),
        },
        { path: PATH_DASHBOARD.chats.Chat, element: <Chatpage /> },
        {
          path: PATH_DASHBOARD.chats.coinsStoreDetails,
          element: (
            <VerticalLayout>
              <CoinsStoreDetailsPage />
            </VerticalLayout>
          ),
        },
        { path: PATH_DASHBOARD.chats.addChat, element: <AddChatPage /> },
        {
          path: PATH_DASHBOARD.chats.addCoin,
          element: (
            <HorizontalLayout title="اضافة عملة">
              <AddCoinPage />
            </HorizontalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.chats.currencyStatus,
          element: (
            <HorizontalLayout title="حالة المكتب">
              <CurrencyStatus />
            </HorizontalLayout>
          ),
        },
      ],
    },
    // Offices
    {
      path: PATH_DASHBOARD.offices.root,
      children: [
        {
          path: PATH_DASHBOARD.offices.officesList,
          element: (
            <VerticalLayout>
              <OfficesListPage />
            </VerticalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.offices.officesDetails,
          element: (
            <VerticalLayout>
              <OfficesDetailsPage />
            </VerticalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.offices.addOffices,
          element: (
            <HorizontalLayout title="اضافة مكتب يدوي">
              <AddOfficesPage />
            </HorizontalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.offices.addTransfer,
          element: (
            <HorizontalLayout title="ارسال حوالة">
              <AddTransferPage />
            </HorizontalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.offices.addSalary,
          element: (
            <HorizontalLayout title="اضافة قبض">
              <AddSalaryPage />
            </HorizontalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.offices.manualOfficeSummery,
          element: (
            <HorizontalLayout title="معلومات المكتب">
              <ManualOfficeSummery />
            </HorizontalLayout>
          ),
        },
      ],
    },
    //  debts And Receivables
    {
      path: PATH_DASHBOARD.debtsAndReceivables.root,

      children: [
        {
          path: PATH_DASHBOARD.debtsAndReceivables.debtsAndReceivablesPage,
          element: (
            <VerticalLayout>
              <DebtsAndReceivablesPage />
            </VerticalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.debtsAndReceivables.addDebtsPage,
          element: (
            <HorizontalLayout title="اضافة دين">
              <AddDebts />
            </HorizontalLayout>
          ),
        },
        {
          path: PATH_DASHBOARD.debtsAndReceivables.addReceivablesPage,
          element: (
            <HorizontalLayout title="اضافة مستحق">
              <AddReceivablesPage />
            </HorizontalLayout>
          ),
        },
      ],
    },
    // total account
    {
      path: PATH_DASHBOARD.totalAccount.root,
      element: <VerticalLayout />,
      children: [
        {
          path: PATH_DASHBOARD.totalAccount.totalAccountPage,
          element: <TotalAccountPage />,
        },
      ],
    },
    // office info
    {
      path: PATH_DASHBOARD.officeInformation.root,
      element: <HorizontalLayout title="معلومات المكتب" />,
      children: [
        {
          path: PATH_DASHBOARD.officeInformation.officeInformationPage,
          element: <OfficeInformationPage />,
        },
        {
          path: PATH_DASHBOARD.officeInformation.editInformationPage,
          element: <EditInformationPage />,
        },
      ],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Auth
// --Login
const LoginPage = Loadable(lazy(() => import("../pages/auth/LoginPage")));
// --Forget Password
const ForgetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ForgetPasswordPage"))
);
// --Reset Password
const ResetPasswordPage = Loadable(
  lazy(() => import("../pages/auth/ResetPasswordPage"))
);

// Overview
// -- Home
const HomePage = Loadable(lazy(() => import("../pages/HomePage")));
// -- Chats
//chat list
const ListChatsPage = Loadable(
  lazy(() => import("../pages/chats/ListChatsPage"))
);
//coins store details
const CoinsStoreDetailsPage = Loadable(
  lazy(() => import("../pages/chats/CoinStoreDetailsPage"))
);
//add chat page
const AddChatPage = Loadable(lazy(() => import("../pages/chats/AddChatPage")));
//add coin page
const AddCoinPage = Loadable(lazy(() => import("../pages/chats/AddCoinPage")));
//currency status page
const CurrencyStatus = Loadable(
  lazy(() => import("../pages/chats/CurrencyStatus"))
);

// -- Offices
//offices list
const OfficesListPage = Loadable(
  lazy(() => import("../pages/offices/OfficesListPage"))
);
//offices details
const OfficesDetailsPage = Loadable(
  lazy(() => import("../pages/offices/OfficesDetailsPage"))
);
//add offices
const AddOfficesPage = Loadable(
  lazy(() => import("../pages/offices/AddOfficesPage"))
);
//add transfer
const AddTransferPage = Loadable(
  lazy(() => import("../pages/offices/AddTransferPage"))
);
//add salary
const AddSalaryPage = Loadable(
  lazy(() => import("../pages/offices/AddSalaryPage"))
);
//Manula Office Summery
const ManualOfficeSummery = Loadable(
  lazy(() => import("../pages/offices/ManualOfficeSummeryPage"))
);

// -- Debts And Receivables
//Debts And Receivables
const DebtsAndReceivablesPage = Loadable(
  lazy(() => import("../pages/DebtsAndReceivables/DebtsAndReceivablesPage"))
);
//Add Debts
const AddDebts = Loadable(
  lazy(() => import("../pages/DebtsAndReceivables/AddDebtsPage"))
);
//add Receivables Page
const AddReceivablesPage = Loadable(
  lazy(() => import("../pages/DebtsAndReceivables/AddReceivablesPage"))
);

// -- Total Account
//Total Account page
const TotalAccountPage = Loadable(
  lazy(() => import("../pages/TotalAccount/TotalAccountPage"))
);
// -- Office info
//Office info page
const OfficeInformationPage = Loadable(
  lazy(() => import("../pages/OfficeInformation/OfficeInformationPage"))
);

//edit info page
const EditInformationPage = Loadable(
  lazy(() => import("../pages/OfficeInformation/EditInformationPage"))
);
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
