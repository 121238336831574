//MUI
import { Box, Menu, MenuItem, Typography } from "@mui/material";
//react
import { useCallback, useEffect, useState } from "react";
//react-router-dom
import { useNavigate } from "react-router-dom";
//__api__
import {
  fetchNotificationRequest,
  setNotificationReadRequest,
} from "src/__api__/notification";
import { PATH_DASHBOARD } from "src/routes/paths";
import EditDeleteConfirmPopUp from "./EditDeleteConfirmPopUp";

//----------------------------------------------------------------------------

export default function NotificationComponent({
  anchorEl,
  handleClose,
  open,
  updateHandler,
}) {
  const navigate = useNavigate();
  // const triggerAlert = useSetRecoilState(alertAtom);

  const [notificationData, setNotificationData] = useState([]);
  console.log(notificationData?.data?.length);

  const [editDeleteConfirmPopUp, triggerEditDeleteConfirmPopUp] =
    useState(false);

  const [triggeredNotification, setTriggeredNotificationData] = useState({});

  const fetchNotificationData = useCallback(async () => {
    fetchNotificationRequest()
      .then((response) => {
        setNotificationData(response);
        updateHandler();
        console.log("notification", response.data);
      })
      .catch((error) => {
        console.log("error fetching Notification", error);
        // triggerAlert({ triggered: true, type: "error", message: "حدث خطا ما" });
      });
  }, []);

  const setNotificationRead = useCallback(async (id) => {
    setNotificationReadRequest(id)
      .then((response) => {
        console.log("notification", response);
      })
      .catch((error) => {
        console.log("error set Notification read", error);
        // triggerAlert({ triggered: true, type: "error", message: "حدث خطا ما" });
      });
  }, []);

  const handleEditDeletePopUp = useCallback(
    (notification) => {
      setTriggeredNotificationData(notification);
      triggerEditDeleteConfirmPopUp(true);
    },
    [
      notificationData,
      triggerEditDeleteConfirmPopUp,
      setTriggeredNotificationData,
    ]
  );

  const handelerNotificationClick = useCallback(
    (index, notification) => {
      const type = notificationData?.data[index]?.data?.notification_type;
      const id = notificationData?.data[index]?.id;
      const listId = notificationData?.data[index]?.data?.data?.id;
      const contactId = notificationData?.data[index]?.data?.data?.contact_id;
      const name =
        notificationData?.data[index]?.data?.data?.offices?.office_name;
      const status = notificationData?.data[index]?.data?.data?.status;
      switch (type) {
        case "reject_received_transaction":
          navigate(PATH_DASHBOARD.chats.coinsStoreDetails, {
            state: { contactId, name, id, status },
          });
          setNotificationRead(id);
          break;
        case "reject_delete_transaction":
          navigate(PATH_DASHBOARD.chats.coinsStoreDetails, {
            state: { contactId, name, id, status },
          });
          setNotificationRead(id);
          break;
        case "reject_edit_transaction":
          navigate(PATH_DASHBOARD.chats.coinsStoreDetails, {
            state: { contactId, name, id, status },
          });
          setNotificationRead(id);
          break;
        case "delete_transaction":
          handleEditDeletePopUp(notification);
          break;
        case "edit_transaction":
          handleEditDeletePopUp(notification);
          break;
        case "send_transaction":
          handleEditDeletePopUp(notification);
          break;
        case "add_contact":
          navigate(PATH_DASHBOARD.chats.coinsStoreDetails, {
            state: { contactId, name, id, status },
          });
          break;
        default:
          break;
      }
      fetchNotificationData();
    },
    [notificationData]
  );

  const handelNotificationView = useCallback(() => {
    let context;
    if (notificationData?.data?.length == 0) {
      context = (
        <MenuItem>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h5">لا يوجد اشعارات</Typography>
          </Box>
        </MenuItem>
      );
    } else {
      context = notificationData?.data?.map((notification, index) => (
        <MenuItem
          key={index}
          onClick={() => handelerNotificationClick(index, notification)}
        >
          <Box sx={{ padding: 5 }}>
            <Typography variant="h5">{notification.data.title}</Typography>
            <Typography variant="body1">{notification.data.message}</Typography>
          </Box>
        </MenuItem>
      ));
    }
    return context;
  }, [notificationData]);

  useEffect(() => {
    fetchNotificationData();
  }, []);

  return (
    <Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto", // Enable scrolling
            maxHeight: 500, // Set a maximum height for the menu
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            // Custom styles to hide scrollbar (for Webkit-based browsers and Firefox)
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari and Opera
            },
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            msOverflowStyle: "none", // Hide scrollbar for IE and Edge
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {handelNotificationView()}
      </Menu>
      <EditDeleteConfirmPopUp
        isTriggered={editDeleteConfirmPopUp}
        data={triggeredNotification}
        closeHandler={() => triggerEditDeleteConfirmPopUp(false)}
        updateHandeler={fetchNotificationData}
      />
    </Box>
  );
}
