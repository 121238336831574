import React, { useCallback, useEffect, useState } from "react";
// react router dom
import { useLocation, useNavigate } from "react-router-dom";
// recoil
import { useSetRecoilState } from "recoil";
// @Mui
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
// paths
import { PATH_AUTH, PATH_DASHBOARD } from "src/routes/paths";
// atoms
import alertAtom from "src/recoil/atoms/alertAtom";
//
import Scrollbar from "src/components/Scrollbar";
import Iconify from "src/components/Iconify";

// --------------------------------------------------------------------------------------

function NavDrawer() {
  const navigate = useNavigate();
  const location = useLocation();

  const triggerAlert = useSetRecoilState(alertAtom);

  const [activePath, setActivePath] = useState(null);

  const navLinks = [
    {
      title: "الدردشات",
      icon: "heroicons-solid:chat-alt-2",
      path: PATH_DASHBOARD.chats.listChats,
    },
    {
      title: "المكاتب اليدوية",
      icon: "material-symbols:account-balance",
      path: PATH_DASHBOARD.offices.officesList,
    },
    {
      title: "الديون والمستحقات",
      icon: "fluent:money-hand-20-filled",
      path: PATH_DASHBOARD.debtsAndReceivables.debtsAndReceivablesPage,
    },
    {
      title: "الحساب الكلي",
      icon: "fluent:wallet-28-filled",
      path: PATH_DASHBOARD.totalAccount.totalAccountPage,
    },
  ];

  const handleLogout = useCallback(() => {
    try {
      localStorage.removeItem("access_token");
      triggerAlert({
        triggered: true,
        type: "success",
        message: "تم تسجيل الخروج بنجاح",
      });
      navigate(PATH_AUTH.login);
    } catch (error) {
      console.log("Error logging out", error);
      triggerAlert({
        triggered: true,
        type: "error",
        message: "حدث خطا ما",
      });
    }
  }, []);

  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(101.96deg, #1E4E87 -6.65%, #518BCF 41.85%, #2C68B0 79.97%, #164885 103.59%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))`,
        width: "22vw",
        position: "fixed",
        zIndex: 10000,
      }}
    >
      {/* Pattern overlay */}

      <Box
        sx={{ position: "absolute", height: "100%", width: "100%", zIndex: 1 }}
      >
        <Box
          component="img"
          src="/wave-pattern.png"
          sx={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </Box>
      {/* End pattern overlay */}
      {/* Content */}
      <Box
        sx={{
          pt: 6,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          zIndex: 10,
        }}
      >
        {/* Logo */}
        <Box
          onClick={() => navigate(PATH_DASHBOARD.overview.home)}
          sx={{
            zIndex: 10,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <Box component="img" src="/logo-white.png" />
        </Box>
        {/* Content */}
        <Scrollbar sx={{ zIndex: 10, mt: 6 }}>
          <Stack direction="column">
            {navLinks.map((navLink, index) => (
              <Box
                key={index}
                onClick={() => navigate(navLink.path)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: { xs: "center", md: "start" },
                  backgroundColor:
                    activePath === navLink.path ? "grey.0" : "transparent",
                  py: 2,
                  px: 2,
                  cursor: "pointer",

                  transition: "all 0.6s ease-in-out", // Smooth transition for background color
                  "&:hover": {
                    backgroundColor: "white", // Change background color on hover
                    "& .nav-icon, .nav-title": {
                      // Apply hover styles to children
                      color: "primary.main",
                    },
                  },
                }}
              >
                <Box sx={{ mr: 2 }}>
                  <Iconify
                    className="nav-icon"
                    sx={{
                      width: 32,
                      height: 32,
                      color:
                        activePath === navLink.path ? "primary.main" : "grey.0",
                      ml: 1,
                    }}
                    icon={navLink.icon}
                  />
                </Box>
                <Typography
                  className="nav-title"
                  color={
                    activePath === navLink.path ? "primary.main" : "grey.0"
                  }
                  variant="h4"
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  {navLink.title}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Scrollbar>
      </Box>
      {/* End Content */}
      <Box sx={{ height: "20%" }} />
      {/* Logout  */}
      <Box
        sx={{
          zIndex: 10,
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={handleLogout}
          sx={{ zIndex: 10, display: "flex" }}
          endIcon={
            <Iconify
              sx={{
                width: 32,
                height: 32,
                transform: "rotate(180deg)",
                color: "grey.0",
                zIndex: 10,
                mr: 1,
              }}
              icon="solar:logout-broken"
            />
          }
        >
          <Typography variant="h4" sx={{ zIndex: 10 }} color="grey.0">
            خروج
          </Typography>
        </Button>
      </Box>
      {/* End logout */}
    </Box>
  );
}

export default NavDrawer;
