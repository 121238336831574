import React, { useCallback, useEffect, useState } from "react";
// @Mui
import {
  Avatar,
  Badge,
  Box,
  Container,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "src/components/Iconify";
// react router dom
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// path
import { PATH_DASHBOARD } from "src/routes/paths";
import NotificationComponent from "src/components/NotificationComponent";
import { fetchNotificationRequest } from "src/__api__/notification";
import { fetchUserRequest } from "src/__api__/officeInfo";

// --------------------------------------------------------------------------------

function Header({ title }) {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [notificationData, setNotificationData] = useState([]);

  const [usereData, setUsereData] = useState([]);

  const fetchUserData = useCallback(async () => {
    fetchUserRequest()
      .then((response) => {
        console.log(response);
        setUsereData(response);
      })
      .catch((error) => {
        console.log("Error fetching user data", error);
      });
  }, []);

  const fetchNotificationData = useCallback(async () => {
    fetchNotificationRequest()
      .then((response) => {
        setNotificationData(response);
        console.log("notification", response.data);
      })
      .catch((error) => {
        console.log("error fetching Notification", error);
        // triggerAlert({ triggered: true, type: "error", message: "حدث خطا ما" });
      });
  }, []);

  useEffect(() => {
    fetchNotificationData();
    fetchUserData();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(open);
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log(open);
  };

  const handleRenderBackButton = useCallback(() => {
    let context;
    if (pathname !== PATH_DASHBOARD.overview.home) {
      context = (
        <Tooltip title="Return Back">
          <IconButton sx={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <Iconify
              sx={{ color: "grey.0", width: 32, height: 32 }}
              icon="iconamoon:arrow-left-2-light"
            />
          </IconButton>
        </Tooltip>
      );
    } else {
      context = (
        <Box>
          <Tooltip title="notification">
            <IconButton onClick={handleOpen} sx={{ cursor: "pointer" }}>
              <Badge
                badgeContent={notificationData?.data?.length}
                color="error"
              >
                <Iconify
                  sx={{ color: "grey.0", width: 32, height: 32 }}
                  icon="mingcute:notification-line"
                />
              </Badge>
            </IconButton>
          </Tooltip>
          <NotificationComponent
            anchorEl={anchorEl}
            handleClose={handleClose}
            open={open}
            updateHandler={fetchNotificationData}
          />
        </Box>
      );
    }

    return context;
  }, [pathname, anchorEl, handleClose, handleOpen, open, navigate]);

  const handleAvatar = useCallback(() => {
    let context;
    if (usereData?.office?.avatar == "null") {
      context = <Avatar />;
    } else {
      context = (
        <Avatar>
          <Box
            component="img"
            src={`https://borsacloud.com/storage/app/public/${usereData?.office?.avatar}`}
          />
        </Avatar>
      );
    }
    return context;
  }, [usereData]);

  return (
    <Box
      sx={{
        position: "fixed",
        width: "100%",
        zIndex: 100000,
        overflow: "hidden",
      }}
    >
      {/* Background wrapper */}
      <Box
        sx={{
          // position: "relative",
          width: "100%",
          height: 100,
          backgroundImage: `linear-gradient(101.96deg, #1E4E87 -6.65%, #518BCF 41.85%, #2C68B0 79.97%, #164885 103.59%), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))`,
        }}
      >
        {/* Pattern overlay */}
        <Box
          sx={{
            position: "absolute",
            right: -300,
            height: "100%",
            width: { xs: "200%", md: "150%" },
            zIndex: 1,
          }}
        >
          <Box
            component="img"
            src="/Vector.svg"
            sx={{
              height: "100%",
              width: { xs: "200%", md: "150%" },
              objectFit: "cover",
            }}
          />
        </Box>
        {/* End pattern overlay */}
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 4,
            }}
          >
            {/* Avatar icon */}
            <Box
              sx={{ zIndex: 100, cursor: "pointer" }}
              onClick={() =>
                navigate(PATH_DASHBOARD.officeInformation.editInformationPage)
              }
            >
              {handleAvatar()}
            </Box>
            {/* End avatar icon */}
            {/* Title */}
            <Box>
              <Typography variant="h3" color="grey.0">
                {title ? title : "Borsa Cloud"}
              </Typography>
            </Box>
            {/* End title */}
            {/* Action Buttons */}
            <Box sx={{ zIndex: 100 }}>{handleRenderBackButton()}</Box>
            {/* End Action Buttons */}
          </Box>
        </Container>
      </Box>
      {/* End background wrapper */}
    </Box>
  );
}

export default Header;
