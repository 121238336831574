// components
import { PATH_DASHBOARD } from "src/routes/paths";
import Iconify from "../../../components/Iconify";
import HomeIcon from "@mui/icons-material/Home";

// ------------------------------------------------------------------------------------------------

const navConfig = [
  {
    subheader: "نظرة عامة",
    items: [
      {
        title: "الرئيسية",
        path: PATH_DASHBOARD.overview.home,
        icon: <Iconify icon="iconoir:home" />,
      },
      {
        title: "الاعدادات",
        path: PATH_DASHBOARD.overview.settings,
        icon: <Iconify icon="mdi-light:settings" />,
      },
    ],
  },
  {
    subheader: "الوسيط المالي",
    /*   items: [
      {
        title: "العملاء",
        icon: <Iconify icon="healthicons:people-outline" />,
        children: [
          { title: "عرض العملاء", path: PATH_DASHBOARD.broker.listClients },
          { title: "اضافة عميل", path: PATH_DASHBOARD.broker.addClients },
        ],
      },
    ], */
  },
];

export default navConfig;
