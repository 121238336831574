// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  forgetPassword: path(ROOTS_AUTH, "/forget-password"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  overview: {
    root: path(ROOTS_DASHBOARD, "/overview"),
    home: path(ROOTS_DASHBOARD, "/overview/home"),
    settings: path(ROOTS_DASHBOARD, "/overview/settings"),
  },
  chats: {
    root: path(ROOTS_DASHBOARD, "/chats"),
    listChats: path(ROOTS_DASHBOARD, "/chats/list-chats"),
    Chat: path(ROOTS_DASHBOARD, "/chats/chat-page"),
    coinsStoreDetails: path(ROOTS_DASHBOARD, "/chats/coins-store-details"),
    addChat: path(ROOTS_DASHBOARD, "/chats/add-chat"),
    addCoin: path(ROOTS_DASHBOARD, "/chats/add-coin"),
    currencyStatus: path(ROOTS_DASHBOARD, "/chats/currency-status"),
  },
  offices: {
    root: path(ROOTS_DASHBOARD, "/offices"),
    officesList: path(ROOTS_DASHBOARD, "/offices/offices-list"),
    officesDetails: path(ROOTS_DASHBOARD, "/offices/offices-details"),
    addOffices: path(ROOTS_DASHBOARD, "/offices/add-offices"),
    addTransfer: path(ROOTS_DASHBOARD, "/offices/add-transfer"),
    addSalary: path(ROOTS_DASHBOARD, "/offices/add-salary"),
    manualOfficeSummery: path(
      ROOTS_DASHBOARD,
      "/offices/manual-office-summery"
    ),
  },
  debtsAndReceivables: {
    root: path(ROOTS_DASHBOARD, "/debts-and-receivables"),
    debtsAndReceivablesPage: path(
      ROOTS_DASHBOARD,
      "/debts-and-receivables/debts-and-receivables-page"
    ),
    addDebtsPage: path(ROOTS_DASHBOARD, "/debts-and-receivables/add-debts"),
    addReceivablesPage: path(
      ROOTS_DASHBOARD,
      "/debts-and-receivables/add-receivables"
    ),
  },
  totalAccount: {
    root: path(ROOTS_DASHBOARD, "/total-account"),
    totalAccountPage: path(
      ROOTS_DASHBOARD,
      "/total-account/total-account-page"
    ),
  },
  officeInformation: {
    root: path(ROOTS_DASHBOARD, "/office-info"),
    officeInformationPage: path(
      ROOTS_DASHBOARD,
      "/office-info/office-info-page"
    ),
    editInformationPage: path(
      ROOTS_DASHBOARD,
      "/office-info/edit-information-page"
    ),
  },
};

export const PATH_DOCS = "https://www.basedontech.com";
