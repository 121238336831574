import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//react
import { useCallback, useEffect, useState } from "react";
//recoil
import { useSetRecoilState } from "recoil";
//api
import { fetchCurrenciesRequest } from "src/__api__/currencies";
import {
  addPhotoTransactionRequest,
  confirmDeleteRequest,
  confirmEditRequest,
  confirmReceivedRequest,
  setNotificationReadRequest,
} from "src/__api__/notification";
import alertAtom from "src/recoil/atoms/alertAtom";
// Yup
import * as Yup from "yup";
// Formik
import { useFormik } from "formik";

//-----------------------------------------------------------------------------------------

const theme = createTheme({
  components: {
    MUIDataTable: {
      styleOverrides: {
        responsiveBase: {
          overflow: "hidden",
        },
      },
    },
  },
});

export default function EditDeleteConfirmPopUp({
  isTriggered,
  closeHandler,
  data,
  updateHandeler,
}) {
  console.log("popup", data);
  const triggerAlert = useSetRecoilState(alertAtom);

  const [currenciesData, setCurrenciesData] = useState([]);

  const [filePreview, setFilePreview] = useState(null);

  const [idPhoto, setIdPhoto] = useState();

  const fetchCurrenciesData = useCallback(async () => {
    fetchCurrenciesRequest()
      .then((response) => {
        setCurrenciesData(response);
      })
      .catch((error) => {
        console.log("Error fetching currencies data", error);
      });
  }, []);

  const renderCurrencyLabel = useCallback(
    (currencyId) => {
      const currency = currenciesData.find((_) => _.id === currencyId);

      return currency?.name;
    },

    [currenciesData]
  );

  const confirmEdit = useCallback(async (id, body) => {
    confirmEditRequest(id, body)
      .then((response) => {
        triggerAlert({
          triggered: true,
          type: "success",
          message: "تم تعديل الحوالة بنجاح",
        });
      })
      .catch((error) => {
        console.log("Error fetching currencies data", error);
      });
    updateHandeler();
  }, []);

  const ConfirmDelete = useCallback(async (id, body) => {
    confirmDeleteRequest(id, body)
      .then((response) => {
        triggerAlert({
          triggered: true,
          type: "success",
          message: "تم حذف الحولة بنجاح",
        });
      })
      .catch((error) => {
        console.log("Error fetching currencies data", error);
      });
    updateHandeler();
  }, []);

  const ConfirmReceived = useCallback(async (id, body) => {
    confirmReceivedRequest(id, body)
      .then((response) => {
        triggerAlert({
          triggered: true,
          type: "success",
          message: "تم قبول الحوالة بنجاح",
        });
      })
      .catch((error) => {
        console.log("Error fetching currencies data", error);
      });
    updateHandeler();
  }, []);

  const setNotificationRead = useCallback(async (id) => {
    setNotificationReadRequest(id)
      .then((response) => {
        console.log("notification", response);
      })
      .catch((error) => {
        console.log("error set Notification read", error);
        // triggerAlert({ triggered: true, type: "error", message: "حدث خطا ما" });
      });
    updateHandeler();
  }, []);

  const handelerClick = useCallback(
    async (id) => {
      if (data?.data?.notification_type === "delete_transaction") {
        ConfirmDelete(id, { status: "delete" });
      } else if (data?.data?.notification_type === "edit_transaction") {
        confirmEdit(id, { response: "agreed" });
      } else if (data?.data?.notification_type === "send_transaction") {
        // ConfirmReceived(id, { status: "received" });
        // handleSubmit();
      }
      closeHandler();
      setNotificationRead(data?.id);
      await updateHandeler();
    },
    [data]
  );

  const handelerRefuseClick = useCallback(
    async (id) => {
      if (data?.data?.notification_type === "delete_transaction") {
        ConfirmDelete(id, { status: "rejected" });
        closeHandler();
      } else if (data?.data?.notification_type === "edit_transaction") {
        confirmEdit(id, { response: "disagreed" });
        closeHandler();
      } else if (data?.data?.notification_type === "send_transaction") {
        closeHandler();
      }
      closeHandler();
      setNotificationRead(data?.id);
      updateHandeler();
    },
    [data]
  );

  useEffect(() => {
    fetchCurrenciesData();
  }, []);

  const formik = useFormik({
    initialValues: {
      holder_img: null,
      holde_notes: "",
    },
    onSubmit: async (values, { resetForm }) => {
      resetForm();
      addPhotoTransactionRequest(data?.data?.data?.id, values)
        .then((response) => {
          console.log("photo added");
        })
        .catch((error) => {
          console.log("Error add photo", error);
        });
    },
  });

  const {
    values,
    setFieldValue,
    errors,
    touched,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    dirty,
  } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue("holder_img", file);
        setFilePreview({
          ...file,
          preview: URL.createObjectURL(file),
        });
      }
    },
    [setFieldValue]
  );

  const handelShow = useCallback(() => {
    let context;
    if (data?.data?.notification_type === "send_transaction") {
      context = (
        <Dialog open={isTriggered} onClose={closeHandler} fullWidth>
          <DialogTitle>{data?.data?.title}</DialogTitle>
          <DialogContent>
            {/* Data Table */}
            <ThemeProvider theme={theme}>
              <Card sx={{ width: { xs: 400, md: 1000 } }}>
                <MUIDataTable
                  columns={[
                    {
                      name: "type",
                      label: "النوع",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "sender_name",
                      label: "المرسل",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "receiver_name",
                      label: "المستفيد",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "date",
                      label: "التاريخ",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {new Date(value).toLocaleString()}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "currency_id",
                      label: "العملة",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {renderCurrencyLabel(value)}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "amount",
                      label: "المبلغ",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "percentage",
                      label: "العمولة",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "total_amount",
                      label: "المبلغ الكلي",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                  ]}
                  data={datatable}
                  options={{
                    elevation: 0,
                    selectableRows: false,
                    setTableProps: () => ({
                      size: "small",
                      "aria-label": "my table",
                      style: { textAlign: "right" }, // Right align for RTL
                    }),

                    textLabels: {
                      body: {
                        noMatch: "عذرًا، لا توجد بيانات متطابقة",
                      },
                      // ... other text labels
                    },
                  }}
                />
              </Card>
            </ThemeProvider>
            {/* End Data Table */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handelerClick(data?.data?.data?.id)}
            >
              تم
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      context = (
        <Dialog open={isTriggered} onClose={closeHandler} fullWidth>
          <DialogTitle>{data?.data?.title}</DialogTitle>
          <DialogContent>
            {/* Data Table */}
            <ThemeProvider theme={theme}>
              <Card sx={{ width: { xs: 400, md: 1000 } }}>
                <MUIDataTable
                  columns={[
                    {
                      name: "type",
                      label: "النوع",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "sender_name",
                      label: "المرسل",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "receiver_name",
                      label: "المستفيد",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "date",
                      label: "التاريخ",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {new Date(value).toLocaleString()}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "currency_id",
                      label: "العملة",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {renderCurrencyLabel(value)}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "amount",
                      label: "المبلغ",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "percentage",
                      label: "العمولة",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: "total_amount",
                      label: "المبلغ الكلي",
                      options: {
                        customBodyRender: (value) => (
                          <Typography sx={{ textAlign: "center" }}>
                            {value}
                          </Typography>
                        ),
                      },
                    },
                  ]}
                  data={datatable}
                  options={{
                    elevation: 0,
                    selectableRows: false,
                    setTableProps: () => ({
                      size: "small",
                      "aria-label": "my table",
                      style: { textAlign: "right" }, // Right align for RTL
                    }),

                    textLabels: {
                      body: {
                        noMatch: "عذرًا، لا توجد بيانات متطابقة",
                      },
                      // ... other text labels
                    },
                  }}
                />
              </Card>
            </ThemeProvider>

            {/* End Data Table */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handelerClick(data?.data?.data?.id)}
            >
              موافقة
            </Button>
            <Button
              onClick={() => handelerRefuseClick(data?.data?.data?.id)}
              sx={{ mr: 2 }}
              variant="outlined"
              color="error"
            >
              رفض
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return context;
  }, [data, closeHandler, filePreview, handleSubmit]);

  const datatable = [
    {
      type: data?.data?.data.type,
      sender_name: data?.data?.data.sender_name,
      receiver_name: data?.data?.data.receiver_name,
      date: data?.data?.data.date,
      currency_id: data?.data?.data.currency_id,
      amount: data?.data?.data.amount,
      percentage: data?.data?.data.percentage,
      total_amount: data?.data?.data.total_amount,
    },
  ];

  return <Box>{handelShow()}</Box>;
}
