//MUI
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
//component
import ChatBar from "./ChatBar";
import AudioPlayer from "./AudioPlayer";
//react
import { useCallback, useEffect, useRef, useState } from "react";
//socket
import io from "socket.io-client";
//react-router-dom
import { useLocation } from "react-router-dom";
//__api__
import {
  fetchLastMessageRequest,
  fetchMessageRequest,
} from "src/__api__/chats";
import { fetchUserRequest } from "src/__api__/officeInfo";

function NormalChat() {
  const location = useLocation();
  const { name, contactId } = location.state || {};
  console.log("contact", contactId);

  const [socket, setSocket] = useState(null);
  const [isLast, setIsLast] = useState(false);
  const [messages, setMessages] = useState([]);
  const [messagesData, setMessagesData] = useState([]);
  const [lastmessagesData, setLastMessagesData] = useState([]);
  const [response, setResponse] = useState([]);

  const [usereData, setUsereData] = useState([]);

  // Add a state to track the currently playing audio message ID
  const [playingAudioMessageId, setPlayingAudioMessageId] = useState(null);

  // Add a ref for the container that will hold the messages
  const messagesContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [usereData]);

  // Method to update the playing state
  const handleAudioPlayToggle = (messageId) => {
    if (playingAudioMessageId === messageId) {
      // If the same message is clicked, stop playing
      setPlayingAudioMessageId(null);
    } else {
      // Play the new message and stop any other
      setPlayingAudioMessageId(messageId);
    }
  };

  const fetchUserData = useCallback(async () => {
    fetchUserRequest()
      .then((response) => {
        console.log(response);
        setUsereData(response);
      })
      .catch((error) => {
        console.log("Error fetching user data", error);
      });
  }, []);

  useEffect(() => {
    const socketIo = io("http://185.124.108.113:8000", {
      path: "/socket.io/",
      transports: ["websocket"],
      extraHeaders: {
        Authorization: contactId,
      },
    });

    setSocket(socketIo);

    socketIo.on("connect", () => {
      console.log("Connected to the WebSocket server!");
      // Here you can emit events or listen for events from the server
    });

    socketIo.on("new-message", (newMessage) => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      console.log("Received a new message: ", newMessage);
    });

    socketIo.on("disconnect", () => {
      console.log("Disconnected from the WebSocket server!");
    });

    return () => {
      socketIo.disconnect();
      console.log("WebSocket disconnected");
    };
  }, [contactId]);

  const sendMessage = (inputMessage) => {
    try {
      socket.emit("new-message", inputMessage);
      console.log("input", inputMessage);
    } catch (error) {
      console.log("err", error);
    }
  };

  const fetchMessagesData = useCallback(async () => {
    fetchMessageRequest(contactId)
      .then((response) => {
        setMessagesData(response.data.data.data);
        console.log(response);
      })
      .catch((error) => {
        console.log("Error fetching messages data", error);
      });
  }, []);

  const fetchResponse = useCallback(async () => {
    fetchMessageRequest(contactId)
      .then((response) => {
        setResponse(response);
        // console.log(response);
      })
      .catch((error) => {
        // console.log("Error fetching messages data", error);
      });
  }, []);

  const fetchLastMessagesData = useCallback(async () => {
    fetchLastMessageRequest(response.data.data.next_page_url)
      .then((response) => {
        const newMessages = response.data.data.data;
        setLastMessagesData(() => [...lastmessagesData, ...newMessages]);
        setResponse(response);
        console.log("last", response);
      })
      .catch((error) => {
        console.log("Error fetching messages data", error);
        setIsLast(true);
      });
  }, [response]);

  useEffect(() => {
    fetchUserData();
    fetchMessagesData();
    fetchResponse();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMessagesData();
    }, 1500);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const chatBarHeight = 100;

  const handleAvatar = useCallback(
    (message, isOwnMessage) => {
      let context;

      if (message?.sender_office?.avatar == "null") {
        context = <Avatar sx={{ marginRight: 1 }} />;
      } else {
        context = (
          <Avatar sx={{ marginRight: 1 }}>
            <Box
              component="img"
              src={`https://borsacloud.com/storage/app/public/${message?.sender_office?.avatar}`}
            />
          </Avatar>
        );
      }

      return context;
    },
    [messagesData]
  );

  return (
    <Container>
      {/* This Box acts as a spacer to ensure content isn't hidden behind the fixed ChatBar */}
      <Box
        ref={messagesContainerRef}
        sx={{
          height: "50vh", // Adjust this value as needed
          overflowY: "scroll",
          scrollbarWidth: "none",
          msOverflowStyle: "none", // For IE and Edge
          scrollBehavior: "smooth", // This enables smooth scrolling
          "&::-webkit-scrollbar": {
            display: "none", // For Chrome, Safari, and Opera
          },
        }}
      >
        <Grid container spacing={2} direction="column-reverse">
          {messagesData.map((message, index) => {
            if (message.message_type === "text") {
              return (
                <ChatMessage
                  key={index}
                  message={message}
                  isOwnMessage={
                    message.sender_office_id === usereData?.office_id
                  }
                  avatar={handleAvatar}
                />
              );
            } else if (message.message_type === "voice") {
              return (
                <AudioPlayer
                  key={index}
                  src={message.voice}
                  message={message}
                  isOwnMessage={
                    message.sender_office_id === usereData?.office_id
                  }
                  avatar={handleAvatar}
                  isPlaying={playingAudioMessageId === message.id} // Add this prop
                  onPlayToggle={() => handleAudioPlayToggle(message.id)} // Add this prop
                />
              );
            } else if (message.message_type === "img") {
              return (
                <ImageMessage
                  message={message}
                  isOwnMessage={
                    message.sender_office_id === usereData?.office_id
                  }
                  avatar={handleAvatar}
                />
              );
            }
          })}
          {lastmessagesData &&
            lastmessagesData?.map((message, index) => {
              if (message.message_type === "text") {
                return (
                  <ChatMessage
                    key={index}
                    message={message}
                    isOwnMessage={
                      message.sender_office_id === usereData?.office_id
                    }
                    avatar={handleAvatar}
                  />
                );
              } else if (message.message_type === "voice") {
                return (
                  <AudioPlayer
                    key={index}
                    src={message.voice}
                    message={message}
                    isOwnMessage={
                      message.sender_office_id === usereData?.office_id
                    }
                    avatar={handleAvatar}
                    isPlaying={playingAudioMessageId === message.id} // Add this prop
                    onPlayToggle={() => handleAudioPlayToggle(message.id)} // Add this prop
                  />
                );
              } else if (message.message_type === "img") {
                return (
                  <ImageMessage
                    message={message}
                    isOwnMessage={
                      message.sender_office_id === usereData?.office_id
                    }
                    avatar={handleAvatar}
                  />
                );
              }
            })}
          {!isLast && (
            <Button onClick={fetchLastMessagesData} sx={{ marginTop: 5 }}>
              الرسائل السابقة
            </Button>
          )}
        </Grid>
        {/* <div ref={messagesEndRef} /> */}
      </Box>
      {/* ChatBar is fixed at the bottom */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          height: `${chatBarHeight}px`,
          backgroundColor: "#fff",
          padding: "10px",
          zIndex: 1000, // Ensure it's above other items
        }}
      >
        {/* Uncomment and use your ChatBar here */}
        <ChatBar handelerSend={sendMessage} contact_id={contactId} />
      </Box>
    </Container>
  );
}

export default NormalChat;

//--------------------------------------------------------------------------------------------

const ChatMessage = ({ message, isOwnMessage, avatar }) => (
  <Grid item xs={12}>
    <Box
      sx={{
        display: "flex",
        justifyContent: !isOwnMessage ? "flex-end" : "flex-start",
        marginY: 1,
      }}
    >
      <Box>
        {isOwnMessage && avatar(message, isOwnMessage)}
        {isOwnMessage && <Typography>{message.sender.name}</Typography>}
      </Box>

      <Paper
        sx={{
          bgcolor: !isOwnMessage ? "#D9D9FF" : "#F3F2FF",
          padding: 1,
          borderRadius: 2,
          borderTopRightRadius: isOwnMessage ? 0 : 2,
          borderTopLeftRadius: isOwnMessage ? 2 : 0,
          maxWidth: 300,
          display: "flex",
          alignItems: "center",
          mx: 1,
        }}
      >
        <Typography variant="body1">{message.text}</Typography>
      </Paper>
      <Box>
        {!isOwnMessage && <Typography>{message.sender.name}</Typography>}
        {!isOwnMessage && avatar(message, isOwnMessage)}
      </Box>
    </Box>
  </Grid>
);

//---------------------------------------------------------------------------------------------
const ImageMessage = ({ message, isOwnMessage, avatar }) => (
  <Grid item xs={12}>
    <Box
      sx={{
        display: "flex",
        justifyContent: !isOwnMessage ? "flex-end" : "flex-start",
        marginY: 1,
      }}
    >
      <Box>
        {isOwnMessage && avatar(message, isOwnMessage)}
        {isOwnMessage && <Typography>{message.sender.name}</Typography>}
      </Box>
      <Box
        component="img"
        src={`https://borsacloud.com/storage/app/public/${message.img}`}
        sx={{ maxWidth: 300 }}
      />
      <Box>
        {!isOwnMessage && <Typography>{message.sender.name}</Typography>}
        {!isOwnMessage && avatar(message, isOwnMessage)}
      </Box>
    </Box>
  </Grid>
);
