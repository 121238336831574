import React from "react";
// React router dom
import { Outlet } from "react-router-dom";
// @Mui
import { Box } from "@mui/material";
//
import NavDrawer from "./NavDrawer";

// -----------------------------------------------------------------------------------

function VerticalLayout({ children }) {
  return (
    <Box sx={{ display: "flex" }}>
      {/* Nav drawer */}
      <NavDrawer />
      {/* Content */}
      <Box sx={{ py: "40px", mr: "25vw", width: "100%" }}>
        <Outlet />
        {children}
      </Box>
    </Box>
  );
}

export default VerticalLayout;
