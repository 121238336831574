import axiosInstance from "./axios";

export const fetchCurrenciesRequest = async () =>
  axiosInstance
    .get("/api/currencies")
    .then((response) => response.data.currencies);

export const creatCurrencyRequest = async (requestData) =>
  axiosInstance.post("/api/currencies", requestData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const creatCurrencyToOfficeRequest = async (requestData) =>
  axiosInstance.post("/api/currencies/office", requestData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const fetchCurrenciesStoreRequest = async () =>
  axiosInstance.get("/api/status").then((response) => response.data);

export const creatStatusRequest = async (requestData) =>
  axiosInstance.post("/api/status", requestData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const fetchStatueDetailsRequest = async (id) =>
  axiosInstance.get(`/api/status/${id}`).then((response) => response.data.data);

export const viewStatuesRequest = async (id, requestData) =>
  axiosInstance
    .put(`/api/status/${id}`, requestData)
    .then((response) => response.data);

export const deleteStatuesRequest = async (id) => {
  return axiosInstance
    .delete(`/api/status/${id}`)
    .then((response) => response.data);
};
