import axiosInstance from "./axios";

export const fetchChatsRequest = async () =>
  axiosInstance
    .get("/api/contact-list")
    .then((response) => response.data.data.data);

export const fetchLastTransRequest = async (contactId) =>
  axiosInstance
    .get(`/api/transaction/office/${contactId}`)
    .then((response) => response.data);

export const fetchAllOfficesRequest = async () =>
  axiosInstance.get("/api/offices").then((response) => response.data.office);

export const addContactRequest = async (requestData) =>
  axiosInstance
    .post(`/api/contact-list`, requestData)
    .then((response) => response.data);

export const searchContactRequest = async (requestData) =>
  axiosInstance
    .post(`/api/offices/search`, requestData)
    .then((response) => response.data);

export const fetchOfficeSummeryRequest = async (id) =>
  axiosInstance
    .get(`/api/transaction/office-summry/${id}`)
    .then((response) => response.data.data);

export const sendMessageRequest = async (requestData) =>
  axiosInstance
    .post(`/api/contact-list/send-message`, requestData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);

export const fetchMessageRequest = async (id) =>
  axiosInstance
    .get(`/api/contact-list/message/${id}`)
    .then((response) => response);

export const fetchLastMessageRequest = async (api) =>
  axiosInstance.get(api).then((response) => response);

export const confirmContactRequest = async (id, requestData) =>
  axiosInstance
    .put(`/api/contact-list/${id}`, requestData)
    .then((response) => response.data.data.data);

export const searchTransactionRequest = async (requestData) =>
  axiosInstance
    .post(`/api/transaction/search`, requestData)
    .then((response) => response.data);
