import React, { useEffect } from "react";
// React router dom
import { useNavigate, useLocation } from "react-router-dom";
// paths
import { PATH_DASHBOARD } from "src/routes/paths";
// Layouts
import HorizontalLayout from "./horizontal"; // Assuming you have this component
import { Box } from "@mui/material";

// --------------------------------------------------------------------------------------------------------------

function LayoutGenerator({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isOverviewHome = location.pathname === PATH_DASHBOARD.overview.home;

  useEffect(() => {
    if (!isOverviewHome) {
      navigate(PATH_DASHBOARD.overview.home, { replace: true });
    }
  }, [navigate, isOverviewHome]);

  if (isOverviewHome) {
    return <HorizontalLayout>{children}</HorizontalLayout>;
  }

  return <Box sx={{ height: "100%" }}>{children}</Box>;
}

export default LayoutGenerator;
