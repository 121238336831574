import React, { useState } from "react";
// @Mui
import { Box, Container } from "@mui/material";
//component
import ChatHeader from "src/components/__chatpage/ChatHeader";
import FirstTimeChat from "src/components/__chatpage/FirstTimeChat";
import NormalChat from "src/components/__chatpage/NormalChat";
import Chattt from "src/components/__chatpage/FirstTimeChat";
//react-router-dom
import { useLocation } from "react-router-dom";

// ----------------------------------------------------------------------

function Chat() {
  const location = useLocation();
  const { status, name, listId, contactId, id, avatar } = location.state || {};

  return (
    <Box sx={{ width: "100%" }}>
      <Container>
        <Box>
          {status == null ? (
            <FirstTimeChat
              name={name}
              id={id}
              listId={listId}
              contactId={contactId}
              avatar={avatar}
            />
          ) : (
            <NormalChat />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default Chat;
