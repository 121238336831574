import React from "react";
// React router dom
import { Outlet } from "react-router-dom";
// @Mui
import { Box } from "@mui/material";
//
import Header from "./Header";

// ----------------------------------------------------------------------------

function HorizontalLayout({ children, title }) {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {/* Header */}
      <Header title={title} />
      {/* Content */}
      <Box>{children ? children : <Outlet />}</Box>
    </Box>
  );
}

export default HorizontalLayout;
