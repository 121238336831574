import React, { useEffect, useState } from "react";
// material
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { MotionLazyContainer } from "./components/animate";
import { ProgressBarStyle } from "./components/ProgressBar";
import ThemeSettings from "./components/settings";
import Alert from "./components/Alert";
import OfflineComponent from "./components/offlineComponent";

// -----------------------------------------------------------------------------

function App() {
  // State to keep track of online status
  const [isOnline, setIsOnline] = useState(true);
  console.log(isOnline);
  useEffect(() => {
    // Function to handle the online status
    const onlineStatus = () => {
      setIsOnline(true);
      console.log("online", isOnline);
    };

    const offlineStatus = () => {
      setIsOnline(false);
      console.log("offline", isOnline);
    };

    // Add event listeners
    window.addEventListener("online", onlineStatus);
    window.addEventListener("offline", offlineStatus);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener("online", onlineStatus);
      window.removeEventListener("offline", offlineStatus);
    };
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ProgressBarStyle />
          {isOnline ? <Router /> : <OfflineComponent />}
          <Alert />
        </LocalizationProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

export default App;
