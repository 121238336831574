import React, { useState, useEffect, useRef } from "react";
import { IconButton, Box, Slider, Grid, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

export default function AudioPlayer({
  src,
  isOwnMessage,
  message,
  avatar,
  isPlaying,
  onPlayToggle,
  isRecording,
}) {
  const audioRef = useRef(
    new Audio(`https://borsacloud.com/storage/app/public/${src}`)
  );
  const [duration, setDuration] = useState(0);
  const currentTimeRef = useRef(0);
  const [sliderValue, setSliderValue] = useState(0); // This state is just for the slider to trigger re-renders

  useEffect(() => {
    const audio = audioRef.current;

    const updateDuration = () => {
      if (isFinite(audio.duration)) {
        setDuration(audio.duration);
      }
    };

    const updateTime = () => {
      currentTimeRef.current = audio.currentTime;
      setSliderValue(audio.currentTime); // Trigger re-render for the slider
    };

    const resetPlayback = () => {
      currentTimeRef.current = 0;
      setSliderValue(0); // Reset slider
      onPlayToggle(); // Notify parent to update the state
    };

    audio.addEventListener("loadedmetadata", updateDuration);
    audio.addEventListener("timeupdate", updateTime);
    audio.addEventListener("ended", resetPlayback);

    return () => {
      audio.removeEventListener("loadedmetadata", updateDuration);
      audio.removeEventListener("timeupdate", updateTime);
      audio.removeEventListener("ended", resetPlayback);
    };
  }, [onPlayToggle, src]);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  const onSliderChange = (event, newValue) => {
    currentTimeRef.current = newValue; // Update current time ref
    audioRef.current.currentTime = newValue; // Update actual audio current time
    setSliderValue(newValue); // Update slider value to trigger re-render
  };

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: !isOwnMessage ? "flex-end" : "flex-start",
          marginY: 1,
        }}
      >
        <Box>
          {isOwnMessage && avatar(message, isOwnMessage)}
          {isOwnMessage && <Typography>{message.sender.name}</Typography>}
        </Box>
        <Box
          sx={{
            bgcolor: !isOwnMessage ? "#D9D9FF" : "#F3F2FF",
            padding: 1,
            borderRadius: 2,
            borderTopRightRadius: isOwnMessage ? 0 : 2,
            borderTopLeftRadius: isOwnMessage ? 2 : 0,
            display: "flex",
            alignItems: "center",
            mx: 1,
            gap: 1,
          }}
        >
          <IconButton onClick={() => onPlayToggle()} size="large">
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Slider
            min={0}
            max={4}
            value={sliderValue}
            onChange={onSliderChange}
            sx={{ width: 200 }}
          />
        </Box>
        <Box>
          {!isOwnMessage && <Typography>{message.sender.name}</Typography>}
          {!isOwnMessage && avatar(message, isOwnMessage)}
        </Box>
      </Box>
    </Grid>
  );
}
