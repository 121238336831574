import axiosInstance from "./axios";

export const fetchNotificationRequest = async () =>
  axiosInstance
    .get(`/api/notifications`)
    .then((response) => response.data.notifications);

export const setNotificationReadRequest = async (id) =>
  axiosInstance
    .get(`/api/notifications/set-read/${id}`)
    .then((response) => response.data);

export const confirmEditRequest = async (id, requestData) => {
  console.log("id", id);
  return axiosInstance
    .put(`/api/transaction/confirm-edit/${id}`, requestData)
    .then((response) => response.data);
};

export const confirmDeleteRequest = async (id, requestData) => {
  console.log("id", id);
  return axiosInstance
    .put(`/api/transaction/confirm-delete/${id}`, requestData)
    .then((response) => response.data);
};

export const confirmReceivedRequest = async (id, requestData) => {
  console.log("id", id);
  return axiosInstance
    .put(`/api/transaction/confirm-received/${id}`, requestData)
    .then((response) => response.data);
};

export const addPhotoTransactionRequest = async (id, requestData) =>
  axiosInstance
    .post(`/api/transaction/transfer-delivery/${id}`, requestData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
